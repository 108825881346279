import React from "react";
import Link from "gatsby-link";
import { randomColor } from "../constants/colors";
import CatCategory from "../components/CatCategory";
import Img from "gatsby-image";

export default ({ work, projectArtlab }) => {
  const cats = work.frontmatter.cat_categories || {},
    categoriesArrayOnTrue = Object.keys(cats || {}).filter(key => cats[key]);
  let classesWrapper = projectArtlab
    ? "squareWorkWrapper projectArtlabWork"
    : "squareWorkWrapper";
  classesWrapper += " " + Object.keys(cats).join(" ");
  return (
    <Link to={work.fields.slug} className={classesWrapper}>
      <div className={classesWrapper} style={{}} key={work.id}>
        <div className="squareWorkInner" style={{ position: "relative" }}>
          <Img
            className="squareWorkImg"
            fluid={work.frontmatter.thumbnail}
            style={{
              position: "absolute"
            }}
          />
          <div
            className="squareWorkInfo"
            style={{ backgroundColor: work.frontmatter.color || randomColor() }}
          >
            <div className="infoTitle">{work.frontmatter.title}</div>
            {categoriesArrayOnTrue &&
            Array.isArray(categoriesArrayOnTrue) &&
            categoriesArrayOnTrue.length > 0 ? (
              <CatCategory
                white={true}
                categoryKey={categoriesArrayOnTrue[0]}
              />
            ) : null}
            <small style={{ display: "none" }}>{work.frontmatter.date}</small>
          </div>
        </div>
      </div>
    </Link>
  );
};
