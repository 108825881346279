import React from "react";
import WorkSquare from "../components/WorkSquare";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import useFilterMenu, { filterNodeWorks } from "../hooks/use-filter-menu";
import { addFluidsToArray } from "../libs/fluid-image";

export default function WorkPage({ data }) {
  let { edges: works } = data.allMarkdownRemark;
  const { edges: allImages } = data.allImageSharp;
  const { category, style } = useFilterMenu();
  works = addFluidsToArray(works, allImages);
  /*
              {
                "excerpt":"LOREM IPSUM ES SIMPLEMENTE EL TEXTO DE RELLENO DE LAS IMPRENTAS Y ARCHIVOS DE TEXTO. LOREM IPSUM HA SIDO EL TEXTO DE RELLENO ESTÁNDAR DE LAS INDUSTRIAS DESDE EL AÑO 1500, CUANDO UN IMPRESOR (N. DEL T. PERSONA QUE SE DEDICA A LA IMPRENTA) DESCONOCIDO USÓ UNA GALERÍA DE .",
                "id":"/Users/macbookpro/proyectos/work-web-portfolio-mauco-sosa/src/pages/work/2018-08-16-london-spectrum-map.md absPath of file >>> MarkdownRemark",
                "fields":{
                  "slug":"/work/2018-08-16-london-spectrum-map/"
                },
                "frontmatter":{
                  "title":"London Spectrum map",
                  "templateKey":"work-post",
                  "date":"August 16, 2018",
                  "cat_categories":null,
                  "cat_styles":null,
                  "thumbnail":"/img/london-spectrum-map.jpg"}
                }
              */

  return (
    <Layout>
      <section className="section">
        <div className="titlePage">
          <h1
            style={{ display: "none" }}
            className="has-text-weight-bold is-size-2"
          >
            Work
          </h1>
        </div>
        <div className="squareWorkFlex">
          {filterNodeWorks(works, category, style).map(({ node: work }) => (
            <WorkSquare key={work.fields.slug} work={work} />
          ))}
        </div>
      </section>
    </Layout>
  );
}

// WorkPage.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array
//     })
//   })
// };

export const pageQuery = graphql`
  query WorkQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "work-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            cat_categories {
              advertising
              books
              corporate
              infographic
              personal
              press
            }
            cat_styles {
              flat
              gradients
              isometric
              linear
              low_poly
            }
            thumbnail
            color
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
            originalName
          }
        }
      }
    }
  }
`;
