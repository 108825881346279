export const colorsBGArray = [
    "#F7A824",
    "#F29167",
    "#4AC9FE",
    "#F7F2EE",
    "#E7A8D5",
    "#F7F6E2",
    "#38C6C4",
    "#429D73",
]

export const randomColor = () => {
    const randomNumber = Math.floor(Math.random() * colorsBGArray.length)
    return colorsBGArray[randomNumber]
}