const getFluidFromImage = (thumbnailToSearch, allImages) => {
  if (typeof thumbnailToSearch !== "string") {
    return thumbnailToSearch;
  }
  const imagePathParts = thumbnailToSearch.split("/");
  const fileName = imagePathParts[imagePathParts.length - 1];
  const found = allImages.filter(i => i.node.fluid.originalName === fileName);
  return found ? found[0].node.fluid : thumbnailToSearch;
};

export const addFluidsToArray = (arr, allImages) =>
  arr.map(elem => {
    const thumbnailToSearch = elem.node.frontmatter.thumbnail;
    elem.node.frontmatter.thumbnail = getFluidFromImage(
      thumbnailToSearch,
      allImages
    );
    return elem;
  });
